<template>
	<div>
		<div class="container">
			<div class="idx-content">
				<!-- 面包屑导航 -->
				<div class="class-nav">当前位置：<router-link tag="a" to="/">首页 </router-link> > 个人资料</div>

				<!-- 个人资料 -->
				<div class="class-box-1 sign-pro-1">
					<div class="class-box-1L">
						<div class="class-box-1L-1">个人资料</div>
						<div class="my">
							<div class="my-box">
								<div class="my-L">
									<div class="my-L-box">
										<div class="my-L-box-1">
											<!-- <img src="@/assets/image/profile.jpg" alt="" /> -->
											<img :src="avatar" alt />
										</div>
										<div class="my-L-box-2" v-if="!rolesShow">{{ info.name }}</div>
										<div v-if="rolesShow" class="myQrCode">
											<div>
												<div class="my-L-box-2" style="text-align: center">
													{{ info.name }}
												</div>
												<!-- <p style="font-size: 16px;text-align:center;">推荐人代码:{{jobNumber}}</p> -->
												<div id="picture" style="width: 110px; height: 110px; margin: 10px 0 20px" @click="toImage">
													<vue-qr
														class="qrcodeImg"
														:logoSrc="imageUrl"
														:logoScale="30"
														:text="InvitationCode"
														:size="500"
													></vue-qr>
													<p style="font-size: 12px; color: #b2b2b2">右键另存为大图</p>
												</div>
												<!-- <p style="font-size: 16px;;">中国质量认证中心</p> -->
												<!-- @click="dialogVisible = true" -->

												<el-popover
													placement="left"
													title=""
													width="330"
													:close-delay="5000"
													trigger="click"
													id="popover"
												>
													<div class="infomsg" ref="bill">
														<div class="ownInfo">
															<p class="messege">请打开手机扫一扫</p>
															<div id="picture">
																<vue-qr :logoSrc="imageUrl" :logoScale="30" :text="InvitationCode" :size="160"></vue-qr>
															</div>
															<div class="username">{{ '佰科' + myJobNumber }}</div>
															<div class="username username2">中国质量认证中心</div>
														</div>
													</div>
													<div style="text-align: center">
														<el-button type="primary" @click="savePic">下 载</el-button>
													</div>
													<el-button type="primary" size="small" slot="reference">我的推荐码</el-button>
												</el-popover>
											</div>
										</div>
									</div>
								</div>
								<!-- 姓名 性别 单位 邮箱 身份证 地址 发票抬头 -->
								<!-- 张三 女 北京广电总局 leoemoji@163.com 441900164589652348 北京市海淀区西二旗育新路10号 -->
								<div class="my-R">
									<div class="my-Rbox">
										<div class="my-R-1">
											<span class="my-R-1L">姓名</span>
											<span class="my-R-1R">{{ info.name }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">性别</span>
											<span class="my-R-1R">{{ sexFormat(info.sex || '3') }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">单位</span>
											<span class="my-R-1R">{{ info.unit }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">邮箱</span>
											<span class="my-R-1R">{{ info.email }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">{{
												selectDictLabel(certificateTypeOptions, info.codeType) || '身份证'
											}}</span>
											<span class="my-R-1R">{{ info.idCode }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">地址</span>
											<span class="my-R-1R">{{ info.address }}</span>
										</div>
										<div class="my-R-1">
											<span class="my-R-1L">发票抬头</span>
											<span class="my-R-1R">{{ info.invoiceUp }}</span>
										</div>
									</div>
									<!-- 修改 -->
									<el-button-group class="my-btns">
										<el-button class="btn m" type="primary" @click="restPasswd">修改密码</el-button>
										<router-link tag="a" to="modification">
											<el-button class="btn" type="primary">修改信息</el-button>
										</router-link>
									</el-button-group>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 个人二维码弹出展示 -->
				<el-dialog title="我的推荐码" :visible.sync="dialogVisible" width="460px" :center="true" top="80px">
					<div class="infomsg" ref="bill">
						<div class="ownInfo">
							<p class="messege">请打开手机扫一扫</p>
							<div id="picture">
								<vue-qr :logoSrc="imageUrl" id="qrCodeImg" :logoScale="30" :text="InvitationCode" :size="300"></vue-qr>
							</div>
							<div class="username">{{ '推荐人代码:佰科' + myJobNumber }}</div>
							<div class="username username2">中国质量认证中心</div>
						</div>
					</div>
					<span slot="footer" class="dialog-footer">
						<!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
						<el-button type="primary" @click="savePic">保 存</el-button>
					</span>
				</el-dialog>
			</div>
		</div>

		<el-dialog title="修改密码" :visible.sync="resetPasswdOpen" width="30%">
			<el-form label-width="80px" :model="restPasswdForm" :rules="restPasswdRoles" ref="restPasswdForm">
				<el-form-item label="旧密码" prop="oldPasswd">
					<el-input
						type="password"
						v-model="restPasswdForm.oldPasswd"
						placeholder="请输入旧密码"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPasswd">
					<el-input
						type="password"
						v-model="restPasswdForm.newPasswd"
						placeholder="请输入新密码"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkPasswd">
					<el-input
						type="password"
						v-model="restPasswdForm.checkPasswd"
						placeholder="请确认密码"
						autocomplete="off"
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="resetPasswdOpen = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getStudent } from '@/api/bm/student';
import { restPasswd } from '@/api/system/user';
import vueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import { Message } from 'element-ui';
import { getConfigKeyiphone } from '@/api/data';
import { generateShortUUID } from '@/utils/jsencrypt';
import CryptoJS from 'crypto-js';

export default {
	components: {
		vueQr,
	},
	data() {
		let checkPasswd = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.restPasswdForm.newPasswd) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};

		return {
			resetPasswdOpen: false,
			myJobNumber: undefined,
			dialogVisible: false,
			rolesShow: false,
			// 二维码logo
			imageUrl: require('../../assets/image/cqc_logo1.png'),
			// 邀请码
			InvitationCode: '',
			certificateTypeOptions: [],
			activeName: 'first',
			active: 0,
			avatar: undefined,
			info: {
				user: {
					avatar: '',
				},
			},
			restPasswdForm: {
				oldPasswd: undefined,
				newPasswd: undefined,
				checkPasswd: undefined,
			},
			restPasswdRoles: {
				oldPasswd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
				newPasswd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
				checkPasswd: [{ required: true, validator: checkPasswd, trigger: 'blur' }],
			},
			sexOptions: [],
		};
	},
	created() {
		// 获取工号
		this.myJobNumber = localStorage.getItem('myJobNumber');
		this.InvitationCode = `${this.$phoneUrl}?jobNumber=${this.myJobNumber}`;
		// if (this.$store.state.user.phoneUrl) {
		//   this.InvitationCode = `${this.$store.state.user.phoneUrl}?jobNumber=${this.myJobNumber}`;
		// } else {
		//   getConfigKeyiphone("iphoneUrl").then((response) => {
		//     this.$store.dispatch("setPhoneUrl", response.msg);
		//     this.InvitationCode = `${response.msg}?jobNumber=${this.myJobNumber}`;
		//   });
		// }

		// getinspectorMy().then((res) => {
		//   console.log("获取工号", res.data.jobNumber);
		//   this.jobNumber = res.data.jobNumber;
		//   this.InvitationCode = `${this.$store.state.user.phoneUrl}?jobNumber=${this.jobNumber}`
		// });
		this.getDicts('sys_user_sex').then((res) => {
			this.sexOptions = res.data;
		});
		this.getDicts('bm_certificate_class').then((res) => {
			this.certificateTypeOptions = res.data;
		});
		this.getInfo();
	},
	mounted() {},
	methods: {
		// 加密
		encryptInformation(word, keyStr, ivStr) {
			const UUID = generateShortUUID(); // 随机生成八位UUID
			let key = CryptoJS.enc.Utf8.parse(`gYagjGhi${UUID}`); // 中与后台一样  密码
			let iv = CryptoJS.enc.Utf8.parse(`dO6sde8Mcr8HMRl9`); // 中与后台一样

			const srcs = CryptoJS.enc.Utf8.parse(word);
			var encrypted = CryptoJS.AES.encrypt(srcs, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});

			return UUID + CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
		},
		submitForm() {
			this.$refs['restPasswdForm'].validate((valid) => {
				if (valid) {
					const params = {
						oldPasswd: this.encryptInformation(this.restPasswdForm.oldPasswd),
						newPasswd: this.encryptInformation(this.restPasswdForm.newPasswd),
						checkPasswd: this.encryptInformation(this.restPasswdForm.checkPasswd),
					};
					restPasswd(params).then((res) => {
						Message({
							message: '修改成功',
							type: 'success',
						});
						this.resetPasswdOpen = false;
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		restPasswd() {
			this.restPasswdForm = {
				oldPasswd: undefined,
				newPasswd: undefined,
				checkPasswd: undefined,
			};
			this.resetPasswdOpen = true;
		},
		toImage() {
			console.log(document.querySelector('#picture>img'));
			let url = document.querySelector('#picture>img').src;
			// let a = document.createElement("a")
			// a.href = url
			// a.click()
			// console.log(url);
			this.preView(url);
			// window.open(url,'_self')
		},
		preView(content) {
			var string = content;
			var iframe = "<iframe width=100% height=100% style='border:none' src='" + string + "'></iframe>";
			var x = window.open();
			x.document.open();
			x.document.write(iframe);
			x.document.close();
		},
		getInfo() {
			let _this = this;
			getStudent().then((res) => {
				this.info = res.data;
				this.avatar = res.data.user.avatar || this.defaultAvatar();
				this.roles = res.data.params.roles;
				if (_this.roles.indexOf('检查员') > -1) {
					_this.rolesShow = true;
				} else {
					_this.rolesShow = false;
				}
				console.log('获取getInfo', this.roles);
			});
		},
		savePic() {
			console.log(this.$refs.bill.scrollHeight);
			window.pageYoffset = 0;
			document.documentElement.scrollTop = 0;
			document.body.scrollTop = 0;
			html2canvas(this.$refs.bill, {
				scale: 1,
				tainttest: true,
				height: this.$refs.bill.scrollHeight,
				windowHeight: this.$refs.bill.scrollHeight,
				useCORS: true,
			}).then((canvas) => {
				console.log('保存图片', canvas);
				let imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
				console.log('保存图片后的url', imageUrl);
				let a = document.createElement('a');
				//设置a标签的href属性（将canvas变成png图片）
				a.href = imageUrl;
				a.download = '佰科平台课程邀请码';
				a.click();
				this.$message({
					message: '下载成功!',
					type: 'success',
				});
			});
		},
		sexFormat(k) {
			return this.selectDictLabel(this.sexOptions, k);
		},
		//
		handleClick(tab, event) {
			console.log(tab, event);
		},
		// 进度条
		next() {
			if (this.active++ > 3) this.active = 0;
		},
	},
};
</script>

<style scoped lang="scss">
.container {
	::v-deep .el-dialog__wrapper {
		// background: red;
		background: transparent !important;
		z-index: 999999 !important;
	}
	height: auto;
	background: #f8f8f8;
	padding-top: 26px;
	padding-bottom: 138px;
	display: flex;

	.myQrCode {
		.infomsg {
			text-align: center;
			display: flex;
			justify-content: space-around;
			z-index: 99999999;
			height: 400px;
			.ownInfo {
				background: url(../../assets/image/qrCode_bg.png) no-repeat;
				background-size: 100%;
				width: 330px;
				height: 400px;
				// box-shadow:  rgba(0,0,0,.1) 0px 0px 50px;
				border: 1px solid #299ee8;
				border-radius: 20px;
				text-align: center;
				// z-index: 99999999;
				// padding-top: 90px;
				// .qrcodeImg{
				//   width: 100% !important;
				// }
				.messege {
					padding: 20px 0 25px;
					font-size: 20px;
					color: #fff;
				}
				.username {
					width: 100%;
					text-align: right;
					padding-right: 25px;
					color: #fff;
					margin-top: 25px;
					font-size: 18px;
					line-height: 1.5;
				}
				.username2 {
					margin: 0;
					font-size: 14px;
				}
			}
		}
	}
	.idx-content {
		width: 100%;
		max-width: 1300px;
		margin: 0 auto;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: auto;
		.class-nav {
			margin-bottom: 37px;
		}
		.class-box-1 {
			height: 236px;
			// border: 1px solid teal;
			.class-box-1L {
				width: 1300px;
				height: auto;
				background: #ffffff;
				float: left;
				// padding-bottom: 70px;
				// position: relative;
				.class-box-1L-1 {
					width: 1300px;
					height: 57px;
					line-height: 57px;
					background: #ffffff;
					box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
					padding-left: 114px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #000000;
					margin-bottom: 4px;
				}
				.class-box-1L-2 {
					width: 1300px;
					height: 175px;
					background: #ffffff;
					box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
					padding-left: 114px;
					position: relative;
					.class-box-1L-2-1 {
						padding-top: 25px;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						img {
							padding-right: 18px;
						}
					}
					.class-box-1L-2-4 {
						width: 190px;
						height: 41px;
						line-height: 41px;
						text-align: center;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						background: linear-gradient(-54deg, rgba(29, 112, 234, 0.89), rgba(72, 140, 240, 0.89));
						border-radius: 4px;
						position: absolute;
						top: 98px;
						right: 79px;
					}
				}
			}
			.class-box-1R {
				width: 262px;
				height: 236px;
				background: #ffffff;
				float: right;
				.class-box-1R-1 {
					width: 262px;
					height: 57px;
					line-height: 57px;
					padding-left: 19px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #000000;
					background: #ffffff;
					box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
				}
				.class-box-1R-2 {
					img {
						width: 80px;
						height: 80px;
						background: #ffffff;
						border-radius: 50%;
						margin-left: 91px;
						margin-top: 24px;
					}
					.class-box-1R-2b {
						display: flex;
						flex-direction: row;
						justify-content: center;
						margin-top: 21px;
						.class-bs-1 {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #000000;
						}
						.class-bs-2 {
							width: 1px;
							height: 20px;
							background: #c0c0c0;
							margin: 0 18px;
							display: inline-block;
						}
						.class-bs-3 {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
						}
					}
				}
			}
		}
		.my {
			width: 1300px;
			height: 560px;
			background: #ffffff;
			box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
			padding-top: 77px;
			.my-box {
				.my-L {
					width: 563px;
					height: 386px;
					border-right: 1px solid #d1d1d1;
					float: left;
					.my-L-box {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.myQrCode {
							text-align: center;
						}
						.my-L-box-1 {
							margin-top: 56px;
							img {
								width: 120px;
								height: 120px;
								border-radius: 50%;
								margin-bottom: 10px;
							}
						}
						.my-L-box-2 {
							font-size: 22px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
						}
						.qrcodeImg {
							width: 100%;
						}
					}
				}
				.my-R {
					width: 730px;
					height: 386px;
					float: left;
					.my-Rbox {
						padding-left: 107px;
						// border: 1px solid teal;
						.my-R-1 {
							margin-bottom: 25px;
							.my-R-1L {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #b2b2b2;
								padding-right: 37px;
								text-align: right;
								width: 60px;
								display: inline-block;
							}
						}
					}
					.my-btns {
						padding-top: 70px;
						.btn {
							width: 198px;
							height: 38px;
							background: linear-gradient(-54deg, rgba(29, 112, 234, 0.89), rgba(72, 140, 240, 0.89));
							border-radius: 4px;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							cursor: pointer;
						}
						.m {
							margin-left: 107px;
							margin-right: 107px;
						}
					}
					.my-btn {
						line-height: 38px;
						text-align: center;
						background: linear-gradient(-54deg, rgba(29, 112, 234, 0.89), rgba(72, 140, 240, 0.89));
						border-radius: 4px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						margin: 86px 0 0 107px;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
